.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom Scrollbar for Glassmorphism Modal */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Thin scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(248, 245, 245, 0.1);
  /* Light translucent track */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(13, 13, 13, 0.4);
  /* Soft white scrollbar */
  border-radius: 10px;
  transition: background 0.3s ease-in-out;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(45, 34, 34, 0.6);
  /* Brighter on hover */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.quill-content {
  @apply text-left leading-relaxed;
}

/* Apply Quill editor styles to the preview */
.quill-content ul {
  @apply list-disc pl-6;
}

.quill-content ol {
  @apply list-decimal pl-6;
}

.quill-content strong {
  @apply font-bold;
}

.quill-content em {
  @apply italic;
}
